import React from "react";
import { AcademicCapIcon, BuildingOffice2Icon, ChevronRightIcon, CalendarIcon, CurrencyPoundIcon, ClockIcon } from '@heroicons/react/24/outline'
import utils from "../../utils/format";
const levelhandle = (levelvalue) => {
	let level = ""
    switch(levelvalue) {
        case "undergraduate": level ='Undergraduate';            
                break
        case "postgraduate": level ='Postgraduate';            
                break
        case "foundation": level ='Foundation';            
                break
        case "research": level ='Research';            
                break  
        default: level ='NA';            
                break  
    }
	return level;
}

const isJson = (str) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}

function List({ courses, setCourse, loading, onClickClearAll }) {
	return (
		<div className="flex h-full w-full flex-col dark:bg-slate-900">
			<ul className="divide-y divide-slate-200">
				{courses?.length ? courses.map((courseRow, courseRowKey) => {
					const course = courseRow.valuesJson;
					return (<li key={courseRowKey} className="flex h-[200px] gap-6 py-2 px-5 cursor-pointer hover:bg-slate-100" onClick={() => setCourse(course)}>
						<div className="flex flex-1 gap-6">
							<div className="flex flex-6 items-center">
							{ course?.institutes?.logo && course?.institutes?.logo != 'NA' ? <img alt="" src={`https://assets.zoomabroad.com/logo/universities/australia/${course?.institutes?.logo}`} className="h-[auto] w-[200px] max-h-[180px]" /> :
								<div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
									<svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
										<path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
									</svg>
								</div> 
							}
							</div>
							<div className="flex flex-1 items-center">
								<div className="flex flex-col gap-2">
									<p className="text-xl leading-6 font-semibold text-gray-900">{course.name}</p>
									<div className="flex gap-4">
										<p className="flex gap-1 text-sm leading-6 text-gray-700"><AcademicCapIcon className="size-6 text-gray-500" /> {levelhandle(course.level)}</p>
										<p className="flex gap-1 text-sm leading-6 text-gray-700"><BuildingOffice2Icon className="size-6 text-gray-500" /> {course?.institutes?.name}</p>
									</div>
									<p className="mt-1 text-sm leading-5 text-gray-700 line-clamp-4">{course.about && course.about != 'NA' && course.about != 'null' ? course.about : course?.institutes?.about}</p>
								</div>
							</div>
						</div>
						<div className="flex items-center gap-6">
							<div className="flex flex-col gap-2 border border-1 p-3 w-[350px]">
								<div className="flex gap-1">
									<p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><CalendarIcon className="size-6 text-gray-500" /> Next Intake:</p>
									<p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.intake ? utils.formatCommaString(course.intake) : 'NA'}</p>
								</div>
								<div className="flex gap-1">
									<p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><CurrencyPoundIcon className="size-6 text-gray-500" /> Annual Cost:</p>
									<p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.course_fee ? `$${course.course_fee}` : 'NA'}</p>
								</div>
								<div className="flex gap-1">
									<p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><ClockIcon className="size-6 text-gray-500" /> Duration:</p>
									<p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.course_duration ? `${course.course_duration} ${course.course_duration_period}` : 'NA'}</p>
								</div>
							</div>
							<ChevronRightIcon className="size-6 text-gray-500 stroke-2" />
						</div>
					</li>
				)}) : loading ? null : 
					<div className="flex flex-col gap-2 h-[200px] items-center justify-center">
						<h2 className="text-xl text-gray-500 font-bold">No matches found for your criteria</h2>
						<p className="text-gray-500">Try adjusting your filters or resetting them to find what you're looking for.</p>
						<button className="text-slate-50 p-2 rounded bg-slate-400 font-semibold" onClick={() => onClickClearAll()}>Clear Filters</button>
					</div>
				}
			</ul>
			{loading ?
				<ul className="divide-y divide-slate-200">
					{[1, 2, 3, 4, 5, 6].map((course, courseKey) => (
						<li key={courseKey} className="flex h-[200px] gap-6 py-2 px-5 cursor-pointer hover:bg-slate-100">
							<div className="flex flex-1 gap-6">
								<div role="status" className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
									<div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
										<svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
											<path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
										</svg>
									</div>
									<div className="w-full">
										<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
										<div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
										<div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
										<div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
										<div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
										<div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
									</div>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
							<div className="animate-pulse flex items-center gap-6">
								<div className="flex flex-col gap-2 border border-1 p-3 w-[350px]">
									<div className="w-full">
										<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
										<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
										<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
									</div>
								</div>
								<ChevronRightIcon className="size-6 text-gray-500 stroke-2" />
							</div>
						</li>
					))}
				</ul> : null}
		</div>
	);
}

export default List;
