import React from "react";
import { AcademicCapIcon, BuildingOffice2Icon, ChevronRightIcon, CalendarIcon, CurrencyPoundIcon, ClockIcon } from '@heroicons/react/24/outline'
import { ArrowDownCircleIcon } from '@heroicons/react/24/solid'
import utils from "../../utils/format";

const levelhandle = (levelvalue) => {
    let level = ""
    switch(levelvalue) {
        case "undergraduate": level ='Undergraduate';            
                break
        case "postgraduate": level ='Postgraduate';            
                break
        case "foundation": level ='Foundation';            
                break
        case "research": level ='Research';            
                break  
        default: level ='NA';            
                break  
    }
    return level;
}

function ListSelected({courses, setCourse, courseSelected, coursesResponse, loading, lastElementRef}) {
  return (
    <div className="flex h-full w-full flex-col dark:bg-slate-900">
            <ul className="divide-y divide-slate-200">
                {courses.map((courseRow, courseRowKey) => {
					const course = courseRow.valuesJson;
                    return (<li key={courseRowKey} className={`flex min-h-[260px] gap-6 py-2 px-5 cursor-pointer hover:bg-slate-100 ${courseSelected.id === courseRow.id ? 'bg-slate-100' : ''}`} onClick={() => setCourse(course)}>
                        <div className="flex flex-1 gap-6">
                            <div className="flex flex-1 items-center justify-between gap-4">
                                <div className="flex flex-col gap-2">
                                    <p className="text-xl leading-6 font-semibold text-gray-900">{course.name}</p>
                                    <div className="flex flex-col gap-2">
                                        <p className="flex gap-1 text-sm leading-6 text-gray-700"><AcademicCapIcon className="size-6 text-gray-500" /> {levelhandle(course.level)}</p>
                                        <p className="flex gap-1 text-sm leading-6 text-gray-700"><BuildingOffice2Icon className="size-6 text-gray-500" /> {course?.institutes?.name}</p>
                                    </div>
                                    <div className="flex flex-col gap-2 border border-1 p-3">
                                        <div className="flex gap-1">
                                            <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><CalendarIcon className="size-6 text-gray-500" /> Next Intake:</p>
                                            <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.intake ? utils.formatCommaString(course.intake) : 'NA'}</p>
                                        </div>
                                        <div className="flex gap-1">
                                            <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><CurrencyPoundIcon className="size-6 text-gray-500" /> Annual Cost:</p>
                                            <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.course_fee ? `$${course.course_fee}` : 'NA'}</p>
                                        </div>
                                        <div className="flex gap-1">
                                            <p className="flex gap-1 text-sm leading-6 text-gray-700 font-semibold"><ClockIcon className="size-6 text-gray-500" /> Duration:</p>
                                            <p className="flex gap-1 text-sm leading-6 text-gray-700">{course?.course_duration ? `${course.course_duration} ${course.course_duration_period}` : 'NA'}</p>
                                        </div>
                                    </div>
                                </div>
                                <ChevronRightIcon className="size-6 text-gray-500 stroke-2" />
                            </div>
                        </div>
                    </li>)
                })}
            </ul>
            {loading ? [1,2,3,4,5,6].map((courseRow, courseRowKey) => {
                return (<li key={courseRowKey} className="flex min-h-[260px] gap-6 py-2 px-5 cursor-pointer hover:bg-slate-100">
                    <div className="flex flex-1 gap-6">
                        <div className="flex flex-1 items-center justify-between gap-4">
                            <div className="flex flex-col gap-2">
                                <div role="status" className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                                    <div className="w-full">
                                        <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[200px] mb-2.5"></div>
                                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[200px] mb-2.5"></div>
                                    </div>
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <div className="animate-pulse flex items-center gap-6">
                                    <div className="flex flex-col gap-2 border border-1 p-3">
                                        <div className="w-full">
                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ChevronRightIcon className="size-6 text-gray-500 stroke-2" />
                        </div>
                    </div>
                </li>)
            }) : null}
            {!loading && coursesResponse.length ? <div ref={lastElementRef} className="flex items-center justify-center my-6">
                <ArrowDownCircleIcon className="size-16 animate-bounce text-gray-500 stroke-2" />
            </div> : null}
    </div>
  );
}

export default ListSelected;
