import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './App.css';
import auJson from './utils/au.json';
import departmentsJson from './utils/departments.json';
import { coursesGetRequest, universitiesGetRequest, initCourseRequest } from './app/course/action';
import Desktop from './desktop/Desktop';
import Mobile from './mobile/Mobile';

const SORTING = [{
	id: 'course_fee',
	order: 'asc',
	label: 'Lowest Fee',
},{
	id: 'institute_rank',
	order: 'desc',
	label: 'Highest Rank',
}]

const MONTHS = [{
	id: 'january',
	label: 'January',
},{
	id: 'february',
	label: 'February',
},{
	id: 'march',
	label: 'March',
},{
	id: 'april',
	label: 'April',
},{
	id: 'may',
	label: 'May',
},{
	id: 'june',
	label: 'June',
},{
	id: 'july',
	label: 'July',
},{
	id: 'august',
	label: 'August',
},{
	id: 'september',
	label: 'September',
},{
	id: 'october',
	label: 'October',
},{
	id: 'november',
	label: 'November',
},{
	id: 'december',
	label: 'December',
}]

const LEVELS = [{
	id: "undergraduate",
	label: 'Undergraduate',
},{
	id: "postgraduate",
	label: 'Postgraduate',
},{
	id: "foundation",
	label: 'Foundation',
},{
	id: "research",
	label: 'Research',
}]

const customTheme = {
    floating: {
      base: 'max-w-72 max-h-48 overflow-y-auto z-10 rounded divide-y divide-gray-100 shadow-lg',
    }
};

function App() {
	const coursesResponse = useSelector((state) => state.course.courses)
	const coursePagination = useSelector((state) => state.course.coursePagination)
	const universitiesResponse = useSelector((state) => state.course.universities)
	// const departments = useSelector((state) => state.course.departments)
	const loading = useSelector((state) => state.course.loading)
	const dispatch = useDispatch()
	const [mode, setMode] = useState(false)
	const [filters, setFilters] = useState([])
	const [sorts, setSorts] = useState([])
	const [course, setCourse] = useState(null)
	const [courses, setCourses] = useState([])
	const [universities, setUniversities] = useState([])
	const [filteredUniversities, setFilteredUniversities] = useState([])
	const [cities, setCities] = useState(auJson)
	const [departments, setDepartments] = useState(departmentsJson)
	const [design, setDesign] = useState('rounded')
	const [bookingLink, setBookingLink] = useState('')
	const [bookingLinkText, setBookingLinkText] = useState('Book a meeting')
	const [color, setColor] = useState('#bc232b')
	const [page, setPage] = useState(0);
	const [pagination, setPagination] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [search, setSearch] = useState('');
	const observer = useRef(null);
	const observerM = useRef(null);

	useEffect(() => {
		if(universitiesResponse) {
			const universitiesObj = universitiesResponse.map(university => {
				const universityJson = university.valuesJson
				return {...universityJson, id: university.id}
			})
			// const ausUniversities = universitiesObj.filter(university => university.country == 'australia')
			setUniversities(universitiesObj)
			setFilteredUniversities(universitiesObj)
		}
	}, [universitiesResponse])

	useEffect(() => {
		const sortsObj = [
			{
			  "field": "institute_name",
			  "order": "asc"
			}
		]
		const filtersObj = [
			{
			  "quick": [],
			  "advance": [
				{
				  "filters": [
					{
					  "key": "country",
					  "condition": "IN",
					  "values": [
						"australia"
					  ]
					}
				  ]
				}
			  ],
			  "search": {}
			}
		]
		dispatch(universitiesGetRequest({filters: filtersObj, sorts: sortsObj}, 0, 500))
	}, [])

	useEffect(() => {
		if(coursePagination) {
			setPage(coursePagination.page)
			setHasMore(!coursePagination.last)
		}
	}, [coursePagination])

	useEffect(() => {
		if(coursesResponse?.length) {
			if(pagination) {
				setPagination(false)
				setCourses((prevCourses) => [...prevCourses, ...coursesResponse]);
			} else {
				setCourses(coursesResponse)
			}
		}
	}, [coursesResponse])
	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
	
		// Clear timer and cancel request on unmount
		const cleanup = () => {
			controller.abort("New request");
			clearTimeout(timer);
		};
	
		// Main logic wrapped in a timer
		const timer = setTimeout(() => {
			if (coursePagination?.page === page || page === 0) {
				setCourses([]);
				dispatch(initCourseRequest());
			}

	
			// Construct filter and sort objects
			const sortsObj = []

			const filtersObj = [{
				"quick": [],
				"advance": [
					{
						"filters": [
							{
								"key": "country",
								"condition": "IN",
								"values": ["australia"]
							}
						]
					}
				],
				"search": {}
			}]
	
			// Build filter objects based on conditions
			const filterMapping = {
				university: { key: 'institutes', condition: 'IN', values: [] },
				level: { key: 'level', condition: 'IN', values: [] },
				intake: { key: 'intake', condition: 'IN', values: [] },
				cities: { key: 'location', condition: 'IN', values: [] },
				departments: { key: 'department_name', condition: 'IN', values: [] },
			};
	
			filters?.forEach(filter => {
				if (filterMapping[filter.model]) {
					filterMapping[filter.model].values.push(filter.id);
				}
			});
	
			// Add filters if they have values
			Object.values(filterMapping).forEach(filter => {
				if (filter.values.length) {
					filtersObj[0].advance[0].filters.push(filter);
				}
			});
	
			// Add search filter
			if (search) {
				filtersObj[0].advance[0].filters.push({
					key: 'name',
					condition: 'LIKE',
					values: [search],
				});
			}

			if(sorts?.length) {
				// Build sort object
				sorts?.forEach(sort => {
					sortsObj.push({
						field: sort.id,
						order: sort.order,
					});
				});
			} else {
				// Default sort object
				sortsObj.push({
					"field": "name",
					"order": "asc"
				});
			}
	
			// Dispatch request with filters, sorts, and signal for aborting
			dispatch(coursesGetRequest({ filters: filtersObj, sorts: sortsObj }, page, signal));
		}, 1200);
	
		// Cleanup on unmount or effect rerun
		return cleanup;
	}, [filters, search, page, sorts]);

	useEffect(() => {
		if (window?.dark) {
			setMode(window?.dark)
		}
		if (window?.design) {
			setDesign(window?.design)
		}
		if (window?.booking_link) {
			setBookingLink(window?.booking_link)
		}
		if (window?.booking_link_text) {
			setBookingLinkText(window?.booking_link_text)
		}
		if (window?.color) {
			setColor(window?.color)
		}
	}, []);

	const onUniversitySearch = (e) => {
		e.preventDefault();
		const searchTerm = e.target.value.toLowerCase();
	
		const filteredUniversities = searchTerm
			? universities.filter(university =>
				university?.institute_name?.toLowerCase().includes(searchTerm)
			  )
			: universities;
	
		setFilteredUniversities(filteredUniversities);
	};

	const onCitySearch = (e) => {
		e.preventDefault();
		const searchTerm = e.target.value.toLowerCase();
	
		const filteredCities = searchTerm
			? auJson.filter(city =>
				city?.city?.toLowerCase().includes(searchTerm)
			  )
			: auJson;
	
		setCities(filteredCities);
	};

	const onDepartmentSearch = (e) => {
		e.preventDefault();
		const searchTerm = e.target.value.toLowerCase();
	
		const filteredDepartments = searchTerm
			? departmentsJson.filter(department =>
				department?.label?.toLowerCase().includes(searchTerm)
			  )
			: departmentsJson;
	
		setDepartments(filteredDepartments);
	};

	const onSearch = (e) => {
		setPage(0)
		setCourse(null)
		setSearch(e.target.value)
	}
	const onFiltersClicked = (e, newValue) => {
		setPage(0)
		setCourse(null)
		e?.preventDefault();
		e?.stopPropagation();
		if(filters.some(e => e.id === newValue.id)) {
			removeFilterClicked(newValue)
		} else {
			setFilters(oldFilters => [...oldFilters, newValue]);
		}
	}
	const onSortingClicked = (e, newValue) => {
		setPage(0)
		setCourse(null)
		e?.preventDefault();
		e?.stopPropagation();
		if(sorts.some(e => e.id === newValue.id)) {
			const sortedArray = filters.filter(e => e.id !== newValue.id)
			setSorts(sortedArray)
		} else {
			setSorts(oldSorts => [...oldSorts, newValue]);
		}
	}
	const removeFilterClicked = (value) => {
		setPage(0)
		setCourse(null)
		const filteredArray = filters.filter(e => e.id !== value.id)
		setFilters(filteredArray)
	}
	const onClickClearAll = () => {
		setPage(0)
		setCourse(null)
		setCourse(null)
		setSearch('')
		setFilters([])
	}

	const lastElementRef = useCallback(
		(node) => {
			console.log("lastElementRef>>>")
		  if (loading || !hasMore) return; // Stop observing if loading or no more posts
	  
		  if (observer.current) observer.current.disconnect(); // Disconnect the previous observer
	  
		  observer.current = new IntersectionObserver(
			(entries) => {
			  if (entries[0].isIntersecting) {
				setPagination(true);
				setPage((prevPage) => prevPage + 1); // Trigger loading of new posts by changing page number
			  }
			},
			{ threshold: 0.1 } // Adjust threshold as needed
		  );
	  
		  if (node) observer.current.observe(node);
		},
		[loading, hasMore, setPage, setPagination]
	);

	const lastElementRefM = useCallback(
		(node) => {
		  if (loading || !hasMore) return; // Stop observing if loading or no more posts
	  
		  if (observerM.current) observerM.current.disconnect(); // Disconnect the previous observer
	  
		  observerM.current = new IntersectionObserver(
			(entries) => {
			  if (entries[0].isIntersecting) {
				setPagination(true);
				setPage((prevPage) => prevPage + 1); // Trigger loading of new posts by changing page number
			  }
			},
			{ threshold: 0.1 } // Adjust threshold as needed
		  );
	  
		  if (node) observerM.current.observe(node);
		},
		[loading, hasMore, setPage, setPagination]
	);
	const count = {
		university: 0,
		level: 0,
		intake: 0,
		cities: 0,
		departments: 0,
	};
	
	// Using forEach to iterate through the filters and directly increment the respective counts
	filters.forEach(({ model }) => {
	if (count.hasOwnProperty(model)) {
		count[model]++;
	}
	});
	return (
		<div className={mode ? 'dark' : 'light'}>
			<div className='hidden md:block lg:block xl:block 2xl:block'>
				<Desktop
					MONTHS={MONTHS}
					LEVELS={LEVELS}
					SORTING={SORTING}
					customTheme={customTheme}
					sorts={sorts}
					onSortingClicked={onSortingClicked}
					onSearch={onSearch}
					search={search}
					count={count}
					onUniversitySearch={onUniversitySearch}
					filteredUniversities={filteredUniversities}
					filters={filters}
					onFiltersClicked={onFiltersClicked}
					onDepartmentSearch={onDepartmentSearch}
					departments={departments}
					onCitySearch={onCitySearch}
					cities={cities}
					onClickClearAll={onClickClearAll}
					course={course}
					courses={courses}
					setCourse={setCourse}
					coursesResponse={coursesResponse}
					loading={loading}
					lastElementRef={lastElementRef}
					removeFilterClicked={removeFilterClicked}
					hasMore={hasMore}
					coursePagination={coursePagination}
				/>
			</div>
			<div className='block md:hidden lg:hidden xl:hidden 2xl:hidden'>
				<Mobile
					MONTHS={MONTHS}
					LEVELS={LEVELS}
					SORTING={SORTING}
					customTheme={customTheme}
					sorts={sorts}
					onSortingClicked={onSortingClicked}
					onSearch={onSearch}
					search={search}
					count={count}
					onUniversitySearch={onUniversitySearch}
					filteredUniversities={filteredUniversities}
					filters={filters}
					onFiltersClicked={onFiltersClicked}
					onDepartmentSearch={onDepartmentSearch}
					departments={departments}
					onCitySearch={onCitySearch}
					cities={cities}
					onClickClearAll={onClickClearAll}
					course={course}
					courses={courses}
					setCourse={setCourse}
					coursesResponse={coursesResponse}
					loading={loading}
					lastElementRef={lastElementRef}
					lastElementRefM={lastElementRefM}
					removeFilterClicked={removeFilterClicked}
					hasMore={hasMore}
				/>
			</div>
		</div>
	);
}

export default App;
